<template>
  <main class="mystamppop">
    <h1>MY스탬프 팝</h1>
    <div class="mystamppop_userinfo">
      <p>사업자 회원</p>
      <h3>
        <span>{{ this.getUser.corporation.name }}</span> 회원님,
        <br class="br" />반갑습니다.
      </h3>
    </div>
    <div class="mystamppop_menu">
      <div class="mystamppop_menu_links">
        <router-link
          :to="{ name: 'MypageAccount' }"
          class="mystamppop_menu_link"
        >
          <img src="@/assets/img/user-update.png" alt="회원정보 수정" />
          <p>회원정보 수정</p>
        </router-link>
        <router-link
          :to="{ name: 'MypagePassword' }"
          class="mystamppop_menu_link"
        >
          <img src="@/assets/img/password.png" alt="비밀번호 변경" />
          <p>비밀번호 변경</p>
        </router-link>
      </div>
      <div class="mystamppop_menu_links">
        <router-link
          :to="{ name: 'MypageFestivalOrder' }"
          class="mystamppop_menu_link"
        >
          <img src="@/assets/img/payment.png" alt="결제내역/진행현황" />
          <p>결제내역/진행현황</p>
        </router-link>
        <router-link
          :to="{ name: 'MypageFestivalReference' }"
          class="mystamppop_menu_link"
        >
          <img src="@/assets/img/data.png" alt="축제 자료 등록" />
          <p>축제 자료 등록</p>
        </router-link>
      </div>
      <div class="mystamppop_menu_links">
        <div @click="sendEmail" class="mystamppop_menu_link">
          <img src="@/assets/img/question.png" alt="1:1문의" />
          <p>1:1문의</p>
        </div>
        <a
          href="https://nextinnovation.notion.site/02bf147159504bdda53d6752f0453f09"
          target="_blank"
          class="mystamppop_menu_link"
        >
          <img src="@/assets/img/notice.png" alt="공지사항" />
          <p>공지사항</p>
        </a>
      </div>
      <div class="mystamppop_menu_links">
        <a
          href="https://nextinnovation.notion.site/d3fd1690607f4015992a1678d03ebf43"
          target="_blank"
          class="mystamppop_menu_link"
        >
          <img src="@/assets/img/privacy.png" alt="개인정보처리방침" />
          <p>개인정보처리방침</p>
        </a>
        <a
          href="https://nextinnovation.notion.site/0afc96309d974fe9bb270d3520383df2"
          target="_blank"
          class="mystamppop_menu_link"
        >
          <img src="@/assets/img/term.png" alt="이용약관" />
          <p>이용약관</p>
        </a>
      </div>
      <div class="mystamppop_menu_links">
        <a
          href="https://nextinnovation.notion.site/3279d5400ab049dc814f2498a63e5b59"
          target="_blank"
          class="mystamppop_menu_link"
        >
          <img src="@/assets/img/marketing.png" alt="마케팅 정보 수신 동의" />
          <p>마케팅 정보 수신 동의</p>
        </a>
        <a
          href="https://nextinnovation.notion.site/3-1488fd5700974609b8e8c721859f85cf"
          target="_blank"
          class="mystamppop_menu_link"
        >
          <img src="@/assets/img/privacy.png" alt="개인정보 제3자 제공 동의" />
          <p>개인정보 제3자 제공 동의</p>
        </a>
      </div>
    </div>
  </main>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["getUser"]),
  },
  methods: {
    sendEmail() {
      window.open("mailto:festival@nextinnovation.kr");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>
